<template>
  <div class="out-box">
    <left-box></left-box>
    <div class="right-box">
      <div class="inputs">
        <input-text :title="'Company (Optional)'" v-model="companyName" ref="companyName"
                    :placeholder="''"></input-text>
        <input-text :title="'Industry (Optional)'" v-model="industry" ref="industry"
                    :placeholder="''"></input-text>
        <a-button class="my-login" @click="toComplete" :loading="loading"><span>Next</span></a-button>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/InputText";
import request from "@/utils/request";

export default {
  components: {
    InputText
  },
  data() {
    return {
      companyName: "",
      industry: "",
      loading: false
    };
  },
  mounted() {
    const userInfo = this.$store.state.user.userInfo;
    console.log("completeInfo mounted", userInfo);
    this.$refs.companyName.setValue(userInfo.companyName);
    this.$refs.industry.setValue(userInfo.industry);
  },
  computed: {},
  methods: {
    toComplete() {
      console.log("toComplete", this.companyName, this.industry);
      // let validResult = true
      // validResult &= this.$refs.companyName.validText()
      // validResult &= this.$refs.industry.validText()
      // if (!validResult) return
      const form = {
        companyName: (this.companyName || "").trim(),
        industry: (this.industry || "").trim()
      };
      const requestConfig = {
        url: "/user/completeInfo",
        method: "post",
        data: {
          companyName: (this.companyName || "").trim(),
          industry: (this.industry || "").trim()
        }
      };
      this.loading = true;
      request(requestConfig).then(() => {
        // this.onRegisterSuccess();
        // this.$router.push({path: '/'})
        this.$router.push({ path: "/" });
        this.loading = false;
      }).catch(() => {
        // this.onRegisterFail()
        this.loading = false;
      });
      this.$router.push({ path: "/" });
    }
  }
};
</script>

<style lang="scss" scoped src="./design.scss"></style>
