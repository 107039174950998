<template>
  <div class="out-box">
    <left-box></left-box>
    <div class="right-box">
      <div class="inputs">
        <input-text :title="'Email'" v-model="mail" ref="mail" valid-rule="email"></input-text>
        <input-vcode :title="'Verification Code'" v-model="verifyCode" :get-mail="()=>this.mail"
                     vcode-type="register" ref="verifyCode"></input-vcode>
        <input-pwd :title="'Password'" v-model="pwd" ref="pwd"></input-pwd>
        <input-pwd :title="'Confirm Password'" v-model="confirmPwd" ref="confirmPwd"></input-pwd>
        <agree-protocol v-model="agreeProtocol" ref="agreeProtocol"></agree-protocol>
        <a-button class="my-login" @click="onRegister" :loading="loading"><span>Start your free trial</span></a-button>
        <div class="my-links flex-column-start-center">
          <a-link class="my-link my-link2" @click="toLogin">Already have an account? Log in</a-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputPwd from '@/components/InputPwd'
import InputText from '@/components/InputText'
import InputVcode from '@/components/InputVcode'
import AgreeProtocol from '@/components/AgreeProtocol'

export default {
  components: {
    InputPwd,
    InputText,
    InputVcode,
    AgreeProtocol
  },
  data() {
    return {
      mail: '',
      verifyCode: '',
      pwd: '',
      confirmPwd: '',
      agreeProtocol: false,

      loading: false
    }
  },
  computed: {},
  methods: {
    onRegister() {
      console.log("onRegister", this.mail, this.pwd, this.verifyCode);
      let validResult = true
      validResult &= this.$refs.mail.validText()
      validResult &= this.$refs.verifyCode.validVcode()
      validResult &= this.$refs.pwd.validPwd()
      validResult &= this.$refs.confirmPwd.validPwd()
      if (this.pwd !== this.confirmPwd) {
        this.$refs.confirmPwd.showErrMsg('Password Inconsistent')
        validResult = false
      }
      if (!this.agreeProtocol) {
        this.$message.warning({ content: "You must accept the Terms of Use and Privacy Policy to create an A4x account" });
        validResult = false
      }
      if (!validResult) return
      const form = {
        email: this.mail.trim(),
        verifyCode: this.verifyCode.trim(),
        password: this.pwd.trim(),
        companyName: '',
        industry: ''
      }
      this.$store.dispatch('user/register', form).then(() => {
        // this.onRegisterSuccess();
        // this.$router.push({path: '/'})
        this.$router.push({path: '/complete-info'})
        this.loading = false
      }).catch(() => {
        // this.onRegisterFail()
        this.loading = false
      })
    },
    toLogin() {
      this.$router.push({path: '/login'})
    }
  }
}
</script>

<style lang="scss" scoped src="./design.scss"></style>
