<template>
  <div class="my-agree">
    <input type="checkbox" class="my-checkbox" v-model="agreeProtocol"/>
    <span style="padding:0 4px">Agree to</span>
    <a-link type="primary" :href="termsOfUseProtocolUrl" target="_blank">Terms Of Use</a-link>
    <span style="padding: 0 4px">and</span>
    <a-link type="primary" :href="privacyPolicyProtocolUrl" target="_blank">Privacy Policy</a-link>
  </div>
</template>

<script>
export default {
  emits: [ 'update:modelValue' ],
  data() {
    return {
      agreeProtocol: false,
      termsOfUseProtocolUrl: 'https://legal.a4x.io/html/terms-of-use/en',
      privacyPolicyProtocolUrl: 'https://legal.a4x.io/html/privacy-policy/en',
    }
  },
  watch: {
    agreeProtocol(newVal, oldVal) {
      this.$emit('update:modelValue', newVal)
    }
  }
}
</script>

<style lang="scss" scoped>

.my-agree {
  margin: 4px 0 16px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}

.my-checkbox {
  width: 14px;
  height: 14px;
  border: 1px solid #A1A1A9;

  //margin-right: 5px;
}

</style>
